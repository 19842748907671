<!-- Step.vue  -->
<template>
    <div class="stepOut">
        <ul style="display: flex;">
            <li class="stepItem" v-for="(item, index) in stepInfo" :key="index">
                <!-- 模拟步骤条的节点，此处为圆圈 -->
                <div :class="item.state? 'icon active':'icon'"></div>
                <!-- 模拟步骤条连接线，动态显示  -->
                <div :class="index<lastIndex? 'line lineActive':'line'" v-show="index!==stepInfo.length-1"></div>
                <!-- 步骤名称  -->
                <p class="stepStatus" :class="item.state?'':'textNo'">{{item.name}}</p>
                <!-- 步骤时间  -->
                <p class="statusTime">{{item.datetime}}</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'steps',
    props: {
        // 传递步骤参数
        stepInfo: {
            type: Array,
            default: []
        },
        lastIndex:{
            type:Number,
            default:0
        }
    }
}
</script>

<style lang="scss" scoped>
.stepOut {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    .stepItem {
        width: 140px;
        height: 70px;
        float: left;
        // font-family: SimSun;
        font-size: 16px;
        text-align: center;
        position: relative;
        .icon {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: rgba(226, 226, 226, 1);
            margin: 0px auto;
            position: relative;
            z-index: 2;
        }
        .active {
            background-color: #1a9dff;
        }
        .line {
            position: absolute;
            top: 3px;
            left: 50%;
            border-bottom: 1px solid #e4e4e4;
            width: 140px;
            z-index: 1;
        }
        .lineActive {
            border-bottom: 1px solid #1a9dff;
        }
        .textNo {
            color: #c9c9c9 !important;
        }
        .stepStatus {
            font-weight: bold;
            font-size: 15px;
            color: #333333;
            line-height: 36px;
        }
        .statusTime {
            font-size: 12px;
            color: #999999;
        }
    }
}
</style>

